import React from 'react'
import { graphql } from 'gatsby'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import HeroUnit from '../components/HeroUnit'
import Layout from '../components/Layout'
import { PortableText } from '@portabletext/react'
import portableTextComponents from '../components/portableTextComponents'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function SanityNewsArticlePage({ data }) {
    const { page } = data

    return (
        <Layout>
            <SEOTools title={page.title} description={page.description} />

            <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay'>
                <Row className='d-flex align-items-center justify-content-center text-center my-5'>
                    <Col md='8'>
                        <h1>{page.title}</h1>
                        {page.subtitle && <h2 className='h4'>{page.subtitle}</h2>}
                    </Col>
                </Row>
            </HeroUnit>

            <section className='mb-5'>
                <div>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='8'>
                                <p className='text-secondary'>{page._createdAt}</p>
                                <PortableText value={page._rawBody} components={portableTextComponents} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        page: sanityNewsArticle(slug: { current: { eq: $slug } }) {
            title
            subtitle
            _createdAt(formatString: "D.M.YYYY")
            _rawBody(resolveReferences: { maxDepth: 1 })
        }
        heroBg: file(relativePath: { eq: "sirumobile_frontpage_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`
