import React from 'react'
import { Link } from 'gatsby'
import { gaLinkClick } from '../utilities/Analytics'
import { scroller } from 'react-scroll'
import urlForSanityImage from '../utilities/urlForSanityImage'

/**
 * @type {{types: {image: (function({value: *}))}, block: {blockquote: (function({children: *}))}}}
 */
const portableTextComponents = {
    types: {
        imageWithCaptionAndAlt: ({ value }) => {
            const source = urlForSanityImage(value.image.asset._ref)
                .fit('max')
                .auto('format')
                .width(1320)
                .quality(90)
                .toString()

            const alt = value.alt || ''

            return (
                <figure>
                    <img src={source} alt={alt} className='w-100 mt-4 mb-4' />
                    {value.caption && (
                        <figcaption className='text-muted fst-italic fw-light small mt-n3 mb-4'>
                            {value.caption}
                        </figcaption>
                    )}
                </figure>
            )
        },
        ctaButton: ({ value }) => (
            <PortableTextLinkOrAnchor value={value} className='btn btn-success btn-lg px-5 d-inline-block mt-3 mb-4' />
        ),
    },
    block: {
        blockquote: ({ children }) => (
            <blockquote className='blockquote border-start border-light border-5 ps-3 py-1'>{children}</blockquote>
        ),
    },
    marks: {
        link: ({ children, value }) => <PortableTextLinkOrAnchor value={value}>{children}</PortableTextLinkOrAnchor>,
    },
}

export const PortableTextLinkOrAnchor = ({ value, children, ...props }) => {
    if (value.href.startsWith('/')) {
        return (
            <Link to={value.href} {...props}>
                {children || value.text}
            </Link>
        )
    } else if (value.href.startsWith('#')) {
        return (
            <a href={value.href} onClick={handleScroll} {...props}>
                {children || value.text}
            </a>
        )
    } else {
        return (
            // eslint-disable-next-line
            <a
                href={value.href}
                // Keep referrer to traffic to our own sites
                rel={value.href.includes('sirumobile.com') ? '' : `noreferrer noopener`}
                target='_blank'
                onClick={gaLinkClick}
                {...props}>
                {children || value.text}
            </a>
        )
    }
}

const handleScroll = e => {
    e.preventDefault()

    scroller.scrollTo(e.currentTarget.hash.substring(1), {
        smooth: 'easeInOutQuart',
        offset: -60,
    })
}

export default portableTextComponents
