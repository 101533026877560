import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
    projectId: process.env.GATSBY_SANITY_CMS_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_CMS_DATASET,
})

const urlForSanityImage = source => builder.image(source)

export default urlForSanityImage
